import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { articleStructuredData } from './structuredData'

const SEO = ({ title, description, lang, meta, type, url, image, date, modified }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          googleSiteVerification,
          fb_app_id,
          defaultTitle,
          defaultDescription,
          author,
          twitterAuthor,
          siteName,
          siteUrl,
          defaultImage,
          defaultLang,
          defaultType
        }
      }
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: image || `${siteUrl}${defaultImage}`,
        url: url || siteUrl,
        type: type || defaultType,
        lang: lang || defaultLang,
        author,
        siteName
      }
      const fileteredMeta = meta.filter(item => {
        if (
          item.name === 'robots' ||
          item.name === 'twitter:card' ||
          item.name === 'twitter:description' ||
          item.name === 'twitter:title' ||
          item.property === 'og:locale' ||
          item.property === 'og:type' ||
          item.property === 'og:url'
        ) return false
      })
      return (
        <>
          <Helmet
            htmlAttributes={{
              lang: seo.lang
            }}
            title={seo.title}
            titleTemplate={`%s | ${seo.siteName}`}
            link = {[
              { rel: 'canonical', href: siteUrl }
            ]}
            meta={[
              {
                name: 'google-site-verification',
                content: googleSiteVerification
              },
              {
                name: 'description',
                content: seo.description
              },
              {
                name: 'author',
                content: seo.author
              },
              {
                name: 'image',
                content: seo.image
              },
              {
                property: 'og:title',
                content: seo.title
              },
              {
                property: 'og:site_name',
                content: seo.siteName
              },
              {
                property: 'og:description',
                content: seo.description
              },
              {
                property: 'og:type',
                content: seo.type
              },
              {
                property: 'og:url',
                content: seo.url
              },
              {
                property: 'og:image',
                content: seo.image
              },
              {
                property: 'og:locale',
                content: seo.lang
              },
              {
                property: 'fb:app_id',
                content: fb_app_id
              },
              {
                name: 'twitter:card',
                content: 'summary_large_image'
              },
              {
                name: 'twitter:site',
                content: twitterAuthor
              },
              {
                name: 'twitter:title',
                content: seo.title
              },
              {
                name: 'twitter:image',
                content: seo.image
              },
              {
                name: 'twitter:description',
                content: seo.description
              },
              {
                name: 'robots',
                content: 'index, follow'
              }
            ].concat(fileteredMeta)}
            script={[{
              type: 'application/ld+json',
              innerHTML: articleStructuredData({
                url: seo.url,
                headline: seo.title,
                authorName: seo.author,
                description: seo.description,
                imageUrl: seo.image,
                type: seo.type,
                date,
                modified
              })
            }]}
          />
        </>
      )
    }}
  />
)

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.string,
  lang: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string
}
SEO.defaultProps = {
  description: null,
  meta: [],
  image: null,
  lang: null,
  type: null,
  url: null
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        googleSiteVerification
        fb_app_id
        twitterAuthor
        defaultTitle: title
        defaultDescription: description
        author
        siteName
        siteUrl: url
        defaultImage: image
        defaultLang: lang
        defaultType: type
      }
    }
  }
`
