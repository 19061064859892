export const articleStructuredData = ({
  url,
  headline,
  authorName,
  imageUrl,
  description,
  type,
  date,
  modified
}) => {
  const structuredData = {
    '@context': 'http://schema.org/',
    '@type': type,
    url,
    headline,
    description
  }
  if (type === 'article') {
    structuredData.author = authorName
    structuredData.image = imageUrl
    structuredData.datePublished = date
    structuredData.dateModified = modified
  }
  if (type === 'website') {
    structuredData.sameAs = [
      'https://www.instagram.com/myna_time/',
      'https://twitter.com/myna_time/',
      'https://www.facebook.com/mynatime/'
    ]
  }
  return JSON.stringify(structuredData)
}
